// FIXME: Potential bad use of snake case, switch everything to camel case
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import dig from "object-dig";
import PropTypes from "prop-types";
import React from "react";
import uuid from "uid";
import { Paginator } from "../../components";
import { humanizeField } from "../../utils/utils";
import SelectAllBtn from "./SelectAllBtn";
import SubmissionScore from "./SubmissionScore";
import SubmissionStatus from "./SubmissionStatus";
import translate from "./texts";

import EditFields from "../../components/EditFields/EditFields";
import "./SubmissionsTable.scss";

dayjs.extend(utc);

const sortingSymbolMap = {
  1: "↑",
  "-1": "↓",
};

const calculateSubmissionActiveState = (
  anySubmissionsSelected,
  selectedSubmissions,
  currentSubmissionId,
  submissionId
) => {
  if (anySubmissionsSelected) {
    return selectedSubmissions[submissionId];
  }
  return currentSubmissionId === submissionId;
};

// TODO: create tests for this dump component.
const SubmissionsTable = ({
  submissions,
  programFieldNames,
  programFields,
  toggleSorting,
  toggleSubmissionSelect,
  selectedSubmissions,
  anySubmissionsSelected,
  collapsed,
  viewSingleSubmission,
  currentSubmissionId,
  sortConfig = {},
  pageUrl,
  totalResult,
  currentPage,
  itemsPerPage,
  deselectAll,
  metadata,
  funnelId,
  rootFunnelId,
  isView,
  fetchSubmissions,
  previousFunnelScores,
}) => {
  const fields = [
    "ID",
    "status",
    "score",
    "labels",
    "createdAt",
    ...programFieldNames,
  ];

  const hasExternalStatuses = submissions.some(
    (s) => typeof s.externalStatus?.status === "string"
  );

  const hasAverageScore = submissions.some((s) => s.averageScore != null);

  const hasMaxScore = submissions.some(
    (s) => typeof s.maxPreviousScore === "number"
  );

  if (hasExternalStatuses) {
    fields.splice(2, 0, "external-status");
  }

  if (hasAverageScore) {
    fields.splice(6, 0, translate("avgScore"));
  }

  if (hasMaxScore) {
    fields.splice(7, 0, translate("maxScore"));
  }

  if (previousFunnelScores?.length > 0) {
    previousFunnelScores.forEach((score, index) => {
      if (!fields.includes(score.funnelName)) {
        fields.splice((hasMaxScore ? 8 : 7) + index, 0, score.funnelName);
      }
    });
  }

  return (
    <div
      className="submissions-table"
      id="SubmissionsTableView"
      key={`submissions-table-${currentPage}`}
    >
      <div className="table-data">
        <div className="table-header">
          {isView && (
            <div className="table-cell checkbox-cell">
              <SelectAllBtn
                selectedSubmissions={selectedSubmissions}
                deselectAll={deselectAll}
                toggleSubmissionSelect={toggleSubmissionSelect}
                submissions={submissions}
                metadata={metadata}
              />
            </div>
          )}
          {!collapsed &&
            fields.map(
              (field) =>
                !(
                  funnelId === rootFunnelId &&
                  ["score", "status"].includes(field)
                ) && (
                  <div
                    key={uuid()}
                    onClick={() => toggleSorting(field)}
                    onKeyUp={() => toggleSorting(field)}
                    role="presentation"
                    className={`sortable table-cell ${
                      field === "ID" ? "id-cell" : ""
                    }`}
                  >
                    {`${translate(humanizeField(field))} ${
                      sortConfig.sortBy === field
                        ? sortingSymbolMap[sortConfig.direction]
                        : ""
                    }`}
                  </div>
                )
            )}
          {collapsed &&
            programFieldNames.length > 0 &&
            ["ID", "status"].map(
              (field) =>
                !(
                  funnelId === rootFunnelId &&
                  ["score", "status"].includes(field)
                ) && (
                  <div
                    key={field}
                    onClick={() => toggleSorting(field)}
                    onKeyUp={() => toggleSorting(field)}
                    role="presentation"
                    className={`table-cell${
                      field === "-" ? " checkbox-cell" : " sortable"
                    }${field === "ID" ? " id-cell" : ""}`}
                  >
                    {`${translate(humanizeField(field))} ${
                      sortConfig.sortBy === field
                        ? sortingSymbolMap[sortConfig.direction]
                        : ""
                    }`}
                  </div>
                )
            )}
        </div>
        {!collapsed &&
          submissions.map((submission) => {
            let onSingleSubmissionClick = () => {};
            if (!anySubmissionsSelected) {
              onSingleSubmissionClick = viewSingleSubmission;
            }
            return (
              <div
                className="submission"
                key={submission.id}
                onClick={() => onSingleSubmissionClick(submission.id)}
                onKeyUp={() => onSingleSubmissionClick(submission.id)}
                role="presentation"
              >
                {isView && (
                  <div className="table-cell checkbox-cell">
                    <input
                      readOnly
                      type="checkbox"
                      checked={selectedSubmissions[submission.id] || false}
                      onClick={() => toggleSubmissionSelect(submission.id)}
                    />
                  </div>
                )}
                <div className="table-cell id-cell">
                  {submission.friendlyId}
                </div>

                {funnelId !== rootFunnelId && (
                  <div className="table-cell">
                    <SubmissionStatus
                      funnelId={funnelId}
                      funnelStatuses={submission.funnelStatuses}
                    />
                  </div>
                )}

                {/* EXTERNAL STATUS */}
                <div className="table-cell">
                  {submission.externalStatus?.status ??
                    submission.externalStatus}
                </div>

                {funnelId !== rootFunnelId && (
                  <div className="table-cell">
                    <SubmissionScore
                      funnelId={funnelId}
                      funnelScores={submission.funnelScores}
                    />
                  </div>
                )}

                <div className="table-cell submission-labels">
                  <div className=" flex gap-2 w-full overflow-y-hidden overflow-x-auto">
                    {submission.labels?.length > 0 &&
                      submission.labels.map((label) => (
                        <div className="label-tag !m-0">{label}</div>
                      ))}
                  </div>
                </div>
                <div className="table-cell">
                  {dayjs(submission.createdAt).utc().format("YYYY-MM-DD")}
                </div>
                {hasAverageScore && (
                  <div className="table-cell">{submission.averageScore}</div>
                )}
                {hasMaxScore && (
                  <div className="table-cell">
                    {typeof submission.maxPreviousScore === "number"
                      ? submission.maxPreviousScore
                      : "-"}
                  </div>
                )}

                {previousFunnelScores?.length > 0 &&
                  previousFunnelScores.map((score, index) => {
                    const funnelScore = submission.funnelScores.find(
                      (s) => s.funnelId === score.funnelId
                    );
                    return (
                      <div key={index} className="table-cell">
                        {funnelScore && funnelScore.score}
                      </div>
                    );
                  })}

                {programFields.map((field) => {
                  const fieldData = dig(
                    submission.data,
                    ...field.id.split(".")
                  );
                  return (
                    <div className="table-cell" key={field.id}>
                      {fieldData ? fieldData.toString() : fieldData}
                    </div>
                  );
                })}
              </div>
            );
          })}
        {collapsed &&
          submissions.map((submission) => {
            const onSingleSubmissionClick = anySubmissionsSelected
              ? toggleSubmissionSelect
              : viewSingleSubmission;
            return (
              <div
                className={`submission ${
                  calculateSubmissionActiveState(
                    anySubmissionsSelected,
                    selectedSubmissions,
                    currentSubmissionId,
                    submission.id
                  )
                    ? "active-submission"
                    : ""
                }`}
                key={submission.id}
                onClick={() => onSingleSubmissionClick(submission.id)}
                onKeyUp={() => onSingleSubmissionClick(submission.id)}
                role="presentation"
              >
                {isView && (
                  <div className="table-cell checkbox-cell">
                    <input
                      readOnly
                      type="checkbox"
                      checked={selectedSubmissions[submission.id] || false}
                      onClick={() => toggleSubmissionSelect(submission.id)}
                    />
                  </div>
                )}
                <div className="table-cell id-cell">
                  {submission.friendlyId}
                </div>
                {funnelId !== rootFunnelId && (
                  <div className="table-cell">
                    <SubmissionStatus
                      funnelId={funnelId}
                      funnelStatuses={submission.funnelStatuses}
                    />
                  </div>
                )}
              </div>
            );
          })}
      </div>
      {totalResult > 15 && (
        <Paginator
          url={pageUrl}
          totalResult={totalResult}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          currentSubmissionId={currentSubmissionId}
        />
      )}
      <div className="w-full p-4">
        <EditFields fetchSubmissions={fetchSubmissions} />
      </div>
    </div>
  );
};
SubmissionsTable.propTypes = {
  submissions: PropTypes.array.isRequired,
  programFieldNames: PropTypes.array.isRequired,
  programFields: PropTypes.array.isRequired,
  toggleSorting: PropTypes.func,
  sortConfig: PropTypes.object,
  collapsed: PropTypes.bool,
  viewSingleSubmission: PropTypes.func.isRequired,
  pageUrl: PropTypes.string.isRequired,
  totalResult: PropTypes.number,
  itemsPerPage: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  currentSubmissionId: PropTypes.string,
  toggleSubmissionSelect: PropTypes.func.isRequired,
  selectedSubmissions: PropTypes.object.isRequired,
  anySubmissionsSelected: PropTypes.bool.isRequired,
  deselectAll: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  funnelId: PropTypes.string.isRequired,
  rootFunnelId: PropTypes.string.isRequired,
  isView: PropTypes.bool,
};

SubmissionsTable.defaultProps = {
  sortConfig: {},
  toggleSorting: () => {},
  collapsed: false,
  currentSubmissionId: "",
  totalResult: 0,
  itemsPerPage: 0,
  isView: true,
};

export default SubmissionsTable;
