// FIXME: Potential bad use of snake case, switch everything to camel case
import PropTypes from "prop-types";
import React from "react";

const getSubmissionStatusInFunnel = (funnelStatuses, funnelId) => {
  if (funnelStatuses) {
    const submissionStatus = funnelStatuses.find(
      (item) => item.funnelId === funnelId
    );
    return submissionStatus ? submissionStatus?.status?.status : "new";
  }

  return "new";
};

const SubmissionStatus = ({ funnelStatuses, funnelId }) => (
  <span id="submissionStatus">
    {getSubmissionStatusInFunnel(funnelStatuses, funnelId)}
  </span>
);

SubmissionStatus.propTypes = {
  funnelId: PropTypes.string.isRequired,
  funnelStatuses: PropTypes.array,
};

SubmissionStatus.defaultProps = {
  funnelStatuses: [],
};

export default SubmissionStatus;
