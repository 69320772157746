import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { Button, Dropdown, Icon, Input, Modal } from "../../components";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Permit from "../../layouts/Authorization";
import "./SubmissionLabel.scss";
import translate from "./texts";

const SubmissionLabel = ({
  isFetching,
  availableLabels,
  addLabel,
  removeLabelFromSubmission,
  deleteLabel,
  submissionLabel,
  creatable,
  programId,
  selectedSubmissionsIds,
}) => {
  const params = useParams();

  const { submissionId: currentSubmissionId } = params;
  const { checkLabel, checkRemoveLabel, allSubmissions } = useSelector(
    (state) => ({
      checkLabel: state.submissions.checkLabel,
      checkRemoveLabel: state.submissions.checkRemoveLabel,
      allSubmissions: state.submissions.submissions,
    })
  );

  const dispatch = useDispatch();

  const [newLabel, setNewLabel] = useState("");

  const getLabelsFromSubmissions = () => {
    if (selectedSubmissionsIds.length == 0) {
      const selectedSubmission = allSubmissions.find(
        (submission) => submission.id === currentSubmissionId
      );
      return selectedSubmission?.labels || [];
    }

    const labels = selectedSubmissionsIds
      .concat(currentSubmissionId)
      .map((submissionId) => {
        const selectedSubmission = allSubmissions.find(
          (submission) => submission.id === submissionId
        );
        return selectedSubmission?.labels || [];
      });

    return labels.flat();
  };

  const [selectedLabels, setSelectedLabels] = useState(
    getLabelsFromSubmissions
  );

  useEffect(() => {
    setSelectedLabels(getLabelsFromSubmissions);
  }, [selectedSubmissionsIds, checkLabel, checkRemoveLabel]);

  const [currentLabel, setCurrentLabel] = useState("");
  const [currentOperation, setCurrentOperation] = useState("");

  const [activateModal, setActivateModal] = useState(false);

  const [toggleNewLabel, setToggleNewLabel] = useState(false);

  const inputRef = useRef(null);

  const onLabelInputChange = (e) => {
    setNewLabel(e.target.value);
  };

  const onLabelInputBlur = (e) => {
    if (e.target.value.length === 0) {
      clearAndToggleInput();
    }
  };

  const onLabelInputKeyUp = (e) => {
    if (e.key === "Enter") {
      addNewLabel();
    }
  };

  const addNewLabel = () => {
    addLabel(newLabel);
    setSelectedLabels((prevSelectedLabels) => [
      ...prevSelectedLabels,
      newLabel,
    ]);
    clearAndToggleInput();
  };

  const clearAndToggleInput = () => {
    setNewLabel("");
    setToggleNewLabel((prevToggleNewLabel) => !prevToggleNewLabel);
  };

  useEffect(() => {
    if (toggleNewLabel && inputRef.current) {
      inputRef.current.querySelector("input").focus();
    }
  }, [toggleNewLabel]);

  const handelAddLabel = (label) => {
    setSelectedLabels((prevSelectedLabels) => [...prevSelectedLabels, label]);
    addLabel(label);
  };

  const handelRemoveLabel = async (label) => {
    setSelectedLabels((prevSelectedLabels) =>
      prevSelectedLabels.filter((selectedLabel) => selectedLabel !== label)
    );
    await removeLabelFromSubmission(label);
    setActivateModal(false);
  };

  console.log(activateModal, "activateModal");
  console.log(checkRemoveLabel, "checkRemoveLabel");
  const handelModalClose = () => {
    setActivateModal(false);
    setCurrentLabel("");
    dispatch({ type: "RESET_CHECK_LABEL" });
  };

  const performCurrentOperation = () => {
    if (currentOperation === "add") {
      handelAddLabel(currentLabel);
    } else if (currentOperation === "remove") {
      handelRemoveLabel(currentLabel);
    }
  };

  useEffect(() => {
    if (checkRemoveLabel || checkLabel) {
      const msg = checkRemoveLabel ? checkRemoveLabel.msg : checkLabel.msg;
      const empty = checkRemoveLabel
        ? checkRemoveLabel.empty
        : checkLabel.empty;

      if (empty) {
        performCurrentOperation();
      } else {
        setActivateModal(true);
      }
    }
  }, [checkRemoveLabel, checkLabel]);

  const trigger = (
    <Button
      loading={isFetching}
      content={translate("label")}
      iconName="label"
      small
      flatWhite
      onClick={() => {}}
    />
  );

  return (
    <div>
      <Dropdown style={{ width: "258px" }} trigger={trigger}>
        {availableLabels.map((label) => (
          <div
            className={`row ${
              selectedLabels.includes(label.key) ? "highlight" : ""
            }`}
            key={label.key}
          >
            <div className="w-full h-full flex items-center px-3 justify-between">
              <div className="flex gap-2">
                <div className="w-5">
                  {selectedLabels.includes(label.key) && (
                    <Icon name="check-bold" className={"text-brand"} />
                  )}
                </div>

                <div className="">{label.key}</div>
              </div>

              <div className="flex gap-8">
                <button
                  className=""
                  onClick={() => {
                    setCurrentLabel(label.key);
                    setCurrentOperation("add");
                    addLabel(label.key, true);
                  }}
                >
                  {/* add */}
                  <Icon name="plus-thick" className={"text-brand"} />
                </button>
                <button
                  className=""
                  onClick={() => {
                    setCurrentLabel(label.key);
                    setCurrentOperation("remove");
                    removeLabelFromSubmission(label.key, true);
                  }}
                >
                  <Icon name="minus-thick" className={"text-red-500"} />
                  {/* remove */}
                </button>
              </div>
            </div>
          </div>
        ))}
        <Permit
          targetPrivilege="submissions.labels.update_or_create"
          targetId={programId}
        >
          {creatable && !toggleNewLabel && (
            <Button
              onClick={clearAndToggleInput}
              content={translate("New label")}
              flat
              small
            />
          )}

          {creatable && toggleNewLabel && (
            <div ref={inputRef} className="row inline-input">
              <div className=""></div>
              <Input
                inputName="new-label"
                placeholder={translate("New label")}
                onChange={onLabelInputChange}
                onBlur={onLabelInputBlur}
                value={newLabel}
                onKeyUp={onLabelInputKeyUp}
              />

              <Button
                onClick={addNewLabel}
                iconName="check"
                flatWhite
                style={{
                  width: newLabel.length > 0 ? "32px" : "0.001px",
                  overflow: "hidden",
                }}
              />
              <Button
                onClick={clearAndToggleInput}
                iconName="close"
                flatWhite
                style={{
                  width: newLabel.length > 0 ? "32px" : "0.001px",
                  overflow: "hidden",
                }}
              />
            </div>
          )}
        </Permit>
      </Dropdown>

      {checkRemoveLabel && (
        <Modal active={activateModal} toggle={handelModalClose}>
          <div className="w-[50vw] h-[50vh]s bg-white p-5 rounded-xl">
            <div>{checkRemoveLabel.msg}</div>

            <div className="flex gap-5 mt-5 w-full justify-center items-center">
              <button
                className="w-28 py-2 bg-brand text-white rounded-md"
                onClick={() => {
                  handelRemoveLabel(currentLabel);
                  setActivateModal(false);
                  setCurrentLabel("");
                }}
              >
                yes
              </button>

              <button
                className="w-28 py-2 text-black rounded-md border border-brand"
                onClick={() => {
                  setActivateModal(false);
                  setCurrentLabel("");
                  dispatch({ type: "RESET_CHECK_LABEL" });
                }}
              >
                no
              </button>
            </div>
          </div>
        </Modal>
      )}

      {checkLabel && (
        <Modal active={activateModal} toggle={handelModalClose}>
          <div className="w-[50vw] h-[50vh]s bg-white p-5 rounded-xl">
            <div>{checkLabel.msg}</div>

            <div className="flex gap-5 mt-5 w-full justify-center items-center">
              <button
                className="w-28 py-2 bg-brand text-white rounded-md"
                onClick={() => {
                  handelAddLabel(currentLabel);
                  setActivateModal(false);
                  setCurrentLabel("");
                }}
              >
                yes
              </button>

              <button
                className="w-28 py-2 text-black rounded-md border border-brand"
                onClick={() => {
                  setActivateModal(false);
                  setCurrentLabel("");
                  dispatch({ type: "RESET_CHECK_LABEL" });
                }}
              >
                no
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

SubmissionLabel.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  availableLabels: PropTypes.array.isRequired,
  addLabel: PropTypes.func.isRequired,
  removeLabelFromSubmission: PropTypes.func.isRequired,
  deleteLabel: PropTypes.func,
  submissionLabel: PropTypes.arrayOf(PropTypes.string),
  creatable: PropTypes.bool,
  programId: PropTypes.string.isRequired,
};

SubmissionLabel.defaultProps = {
  submissionLabel: [],
  creatable: false,
  deleteLabel: () => {},
};

export default SubmissionLabel;
